@tailwind base;
@tailwind components;
@tailwind utilities;

/* Charts */

.switcher {
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 8px;
  color: #2196f3;
}

.switcher-item {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  padding: 6px 8px;
  font-size: 14px;
  color: #262b3e;
  background-color: transparent;
  margin-right: 8px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.switcher-item:hover {
  background-color: #f2f3f5;
}

.switcher-active-item {
  text-decoration: none;
  cursor: default;
  color: #262b3e;
}

.switcher-active-item,
.switcher-active-item:hover {
  background-color: #e1eff9;
}

#messages-render {
  scroll-behavior: smooth;
}
